//Import React Library
import React, { Component, Suspense, lazy } from "react";

//import react-router
import { Switch, Route } from "react-router-dom";

//import loading animation
import PacmanLoader from "react-spinners/PacmanLoader";
import { css } from "@emotion/core";
//import DocVisualizer from "../Layouts/DocumentVisualizer/DocVisualizer";

//import layouts
const DocumentVisualizer = lazy(() =>
  import("../Layouts/DocumentVisualizer/index")
);

const Home = lazy(() => import("../Layouts/Home/index"));
//const Home = lazy(() => import("@bit/lexisdev.lexisdevcomponents.layouts.home"));
/* <Route path="/legislacion/visualize-document" component={DocumentVisualizer} />
                    <Route path="/legislacion/compare-document" component={DocumentCompare} />
                    <Route path="/legislacion/just-read-document" component={DocumentJustRead} />*/

const override = css`
  display: block;
  margin: auto auto;
`;

export default class Content extends Component {
  render() {
    return (
      <Suspense
        fallback={
          <div className="col loadingScreen text-center">
            <PacmanLoader
              css={override}
              size={15}
              color={"var(--mainTotal)"}
              loading={true}
            />
            <p className="text-center mt-4">Cargando</p>
          </div>
        }
      >
        <Switch>
          <Route exact path="/" component={Home} />
          <Route
            path="/legislacion/visualize-document"
            component={DocumentVisualizer}
          />

          <Route component={Home} />
        </Switch>
      </Suspense>
    );
  }
}
