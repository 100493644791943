import React from "react";
import ReactDOM from "react-dom";
import Legislacion from "./Legislacion.js";
import { MyProvider } from "./Context/context.js";
import { BrowserRouter as Router } from "react-router-dom";
export default function Root(props) {
  return (
    <MyProvider>
      <Router>
        <Legislacion />

        <div id="modal-just-read-document"></div>
      </Router>
    </MyProvider>
  );
}
