import React, { useState, useContext, useEffect } from "react";
import useModal from "../Components/Modals/useModal.js";
import Modal from "../Components/Modals/Modal.js";
// make a new context
export const MyContext = React.createContext();
export const useMyContext = () => useContext(MyContext);

// create the provider
export const MyProvider = (props) => {
  const [menuOpenState, setMenuOpenState] = useState(false);

  return (
    <MyContext.Provider
      value={{
        isMenuOpen: menuOpenState,

        toggleMenu: () => setMenuOpenState(!menuOpenState),
        stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen),
      }}
    >
      <Modal />
      {props.children}
    </MyContext.Provider>
  );
};
