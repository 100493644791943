import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useMyContext } from "../../Context/context.js";
import "./modal.scss";

import "./datePicker.scss";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
registerLocale("es", es);
const Modal = () => {
  const {
    modalContent,
    handleModal,
    modal,
    setPartialFilterArray,
  } = useMyContext();
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [day, setDay] = useState(null);
  if (modal) {
    return ReactDOM.createPortal(
      <div
        className="fixed top-0 left-0 h-screen w-full flex items-center justify-center z-50"
        style={{ background: "rgba(0,0,0,0.8)" }}
      >
        <div className="bg-white relative p-5 shadow-lg rounded flex flex-col items-start text-lg text-gray-800">
          <button
            className="absolute top-0 right-0 -mt-12 font-bold self-end rounded-full bg-red-200 mb-3 bg-white text-red-700 w-8 h-8"
            onClick={() => handleModal()}
          >
            &times;
          </button>

          {parseInt(modalContent) <= 1 ? (
            <React.Fragment>
              <p className="">Seleccione los parámetros de búsqueda</p>
              <div className="row noSpace">
                <div className="col noSpace px-2">
                  <p className="label">Año</p>
                  <DatePicker
                    locale="es"
                    selected={year !== null ? new Date(year, 0) : null}
                    onChange={(date) => setYear(date.getFullYear())}
                    showYearPicker
                    dateFormat="yyyy"
                    className="toolbarInput yearMonthInput filterInput w-100 mx-auto my-3"
                    calendarClassName="calendarPopper"
                    maxDate={new Date()}
                    placeholderText="Todos"
                  >
                    <button
                      className="toolbarTodosBtn mx-auto"
                      onClick={() => setYear(null)}
                    >
                      Todos
                    </button>
                  </DatePicker>
                </div>
                <div className="col noSpace px-2">
                  <p className="label">Mes</p>
                  <DatePicker
                    locale="es"
                    selected={month !== null && new Date(2020, month)}
                    onChange={(date) => setMonth(date.getMonth())}
                    renderCustomHeader={({}) => (
                      <p className="noSpace">Seleccione un mes</p>
                    )}
                    dateFormat="MM"
                    showMonthYearPicker
                    showPopperArrow={false}
                    className="toolbarInput yearMonthInput filterInput mx-auto my-3"
                    calendarClassName="monthCalendarPopper"
                    placeholderText="Todos"
                    popperPlacement="bottom-center"
                    popperModifiers={{
                      preventOverflow: {
                        enabled: true,
                      },
                    }}
                  >
                    <button
                      className="toolbarTodosBtn"
                      onClick={() => setMonth(null)}
                    >
                      Todos
                    </button>
                  </DatePicker>
                </div>
                <div className="col noSpace px-2">
                  <p className="label">Día</p>
                  <DatePicker
                    locale="es"
                    selected={day !== null ? new Date(2020, 0, day) : null}
                    onChange={(date) => setDay(date.getDate())}
                    renderCustomHeader={({}) => (
                      <p className="noSpace w-100">Seleccione un día</p>
                    )}
                    dateFormat="dd"
                    className="toolbarInput yearMonthInput filterInput mx-auto my-3"
                    calendarClassName="dayCalendarPopper"
                    placeholderText="Todos"
                    /* minDate={((context.year===0)||(context.month===0))?new Date(2020,1,1):new Date(context.year,context.month,1)}
                                     maxDate={((context.year===0)||(context.month===0))?new Date(2020,1,31):new Date(context.year,context.month+1,0)}*/
                    popperClassName="some-custom-class"
                    popperPlacement="top-end"
                    popperModifiers={{
                      preventOverflow: {
                        enabled: true,
                      },
                    }}
                  >
                    <button
                      className="toolbarTodosBtn"
                      onClick={() => setDay(null)}
                    >
                      Todos
                    </button>
                  </DatePicker>
                </div>
              </div>
              <button
                className="buscarBtn"
                onClick={() => (
                  setPartialFilterArray({
                    anio: year,
                    mes: month !== null ? month + 1 : null,
                    dia: day,
                    tipoFecha: modalContent,
                  }),
                  handleModal()
                )}
              >
                Buscar
              </button>
            </React.Fragment>
          ) : (
            <p>modal de info doc</p>
          )}
        </div>
      </div>,
      document.querySelector("#modal-filter-dates")
    );
  } else return null;
};

export default Modal;
