import React from "react";

import Routes from "./Components/Router/Routes";
import SectionTitle from "./SectionTitle";

import titleIcon from "./legislacion-title.png";
export default function Legislacion() {
  return (
    <div className="counter">
      <SectionTitle src={titleIcon} title="Legislacion" />
      <Routes />
    </div>
  );
}
