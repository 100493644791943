//Import React Library
import React, { Component } from "react";

import Rectangle from "./Rectangle";
export default class SectionTitle extends Component {
  render() {
    return (
      <div className="col noSpace pt-3 pt-md-4 pt-lg-55 px-4">
        <div className="row noSpace">
          <div
            size="6"
            md="4"
            lg="3"
            xl="2"
            className="col-6 cols-md-4 col-lg-3 col-xl-2"
          >
            <img //image
              className="img-fluid"
              src={this.props.src}
              alt="title"
            ></img>
          </div>
          <div className="noSpace my-auto">
            <Rectangle width="50" height="50" color="#13Adbd" />
          </div>
          <div className="col noSpace pl-4 my-auto">
            <Rectangle width="100%" height="50" color="#153542" />
          </div>
        </div>

        {/**Title */}
      </div>
    );
  }
}
